html {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

html::-webkit-scrollbar {
  width: 0px;
}

@font-face {
  font-family: 'BYekan';
  src: url('./assets/fonts/BYekan.woff') format('woff');
}

@font-face {
  font-family: 'sans';
  src: url('./assets/fonts/IRANSansWeb(FaNum).woff') format('woff');
}

@font-face {
  font-family: 'sans-bold';
  src: url('./assets/fonts/IRANSansWeb(FaNum)_Bold.woff') format('woff');
}

* {
  font-family: BYekan;
  font-size: 12px;
}

.App {
  font-family: BYekan;
  font-size: 12px;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btnNew {
  float: left;
  width: 100px;
  height: 35px;
  cursor: pointer;
  padding: 8px;
  font-size: 16;
  text-align: center;
  border-radius: 5px; 
  background-color: #078a53;
  color: rgb(255, 255, 255);
}

.btnSave {
  margin: 0 auto;
  width: 110px;
  height: 35px;
  cursor: pointer;
  padding: 8px;
  font-size: 16;
  text-align: center;
  border-radius: 5px; 
  background-color: #078a53;
  color: rgb(255, 255, 255);
}

.btnEdit {
  width: 110px;
  height: 35px;
  cursor: pointer;
  padding: 8px;
  font-size: 16;
  text-align: center;
  border-radius: 5px; 
  background-color: #eeb005;
  color: rgb(255, 255, 255);
}

.btnCancel {
  width: 110px;
  height: 35px;
  cursor: pointer;
  padding: 8px;
  font-size: 16;
  text-align: center;
  border-radius: 5px; 
  background-color: #8d9994;
  color: rgb(255, 255, 255);
}

.btnIndex {
  float: left;
  width: 110px;
  height: 35px;
  cursor: pointer;
  padding: 8px;
  font-size: 16;
  text-align: center;
  border-radius: 5px; 
  background-color: #2ba4f5;
  color: rgb(255, 255, 255);
}
